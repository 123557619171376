import React, { useContext, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import i18n from 'i18next';
import { classList } from '../Helpers/Functions';
import { withTranslation } from 'react-i18next';
import UtilsContext from '../Context/utilsContext';
import { DOWNLOAD_FILE } from '../hooks/useAPI/actions';
import useAPI from '../hooks/useAPI';

function DropdownTree({ t, is_filter, currentBuildings, currentBuildingsChecked, setCurrentBuildingsChecked, setCurrentBuildings }) {

  const WEEK_IN_DAYS = 7;
  const QR_OPTION = 0;
  const CSV_OPTION = 1;
  const PDF_OPTION = 2;
  const NOW_DATE_START = new Date();
  const NOW_DATE_END = new Date();

  const [ dropdownStatus, setDropdownStatus ] = useState(false);
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const [currentOption, setCurrentOption] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [openRange, setOpenRange] = useState(false);
  const [dateStart, setDateStart] = useState(new Date(NOW_DATE_START.setDate(NOW_DATE_START.getDate() - WEEK_IN_DAYS)));
  const [dateEnd, setDateEnd] = useState(NOW_DATE_END);
  const [pdfFormType, setPdfFormType] = useState('');

  const { customers } = useContext(UtilsContext);
  const [customerData, setCustomerData] = useState(undefined);
  const [lang, setLang] = useState('es');
  const dropdownRef = useRef(null);
  const [displayMessage, setDisplayMessage] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)

  const { callAPI, apiResult } = useAPI();
  const { data } = apiResult;

  const ms_timeout = 5500

  const onChangeRange = (dates) => {
    const [start, end] = dates;
    setDateStart(start);
    setDateEnd(end);
  };

  useEffect(() => {
    setCustomerData(customers);
  }, [customers]);

  useEffect(() => {
    if (customerData !== undefined && customerData.buildings !== undefined && !is_filter) {
      let temp = []
      customerData.buildings.forEach(building => {
        let temp_zones = []
        building.zones.forEach(zone => {
          temp_zones.push({ value: zone.name, id: zone.id })
        })
        temp.push({ value: building.name, tree: false, zones: temp_zones })
      })
      setCurrentBuildings(temp)
    }
  }, [customerData])

  function buildingOpenTree(index) {
    let temp = [...currentBuildings]
    temp[index].tree = !temp[index].tree
    setCurrentBuildings(temp)
  }

  function buildingChecked(val, checked) {
    let temp = [...currentBuildingsChecked]
    let isArray = Array.isArray(val)
    if (checked) {
      if (isArray) {
        temp = temp.concat(val)
      } else {
        temp.push(val)
      }
    } else {
      if (isArray) {
        let newVal = new Set(val)
        temp = temp.filter(x => !newVal.has(x))
      } else {
        const index = temp.indexOf(val);
        if (index > -1) {
          temp.splice(index, 1);
        }
      }
    }
    setCurrentBuildingsChecked(temp)
  }

  function hasAllValues(arr1, arr2) {
    const set2 = new Set(arr2);
    if (set2.length >= 1 || arr1.length >= 1) {
      return arr1.every(elem => set2.has(elem));
    }
    return false
  }

  function haveCommonValue(arr1, arr2) {
    const elementSet = new Set(arr1);
    for (let i = 0; i < arr2.length; i++) {
      if (elementSet.has(arr2[i])) {
        return true;
      }
    }
    return false;
  }

  function download(type) {
    if (dateStart === null || dateEnd === null) return

    let temp = []
    let unique_ids = []
    let int_name_buildings = currentBuildingsChecked.filter(item => typeof item === 'number')
    let str_name_buildings = currentBuildingsChecked.filter(item => typeof item === 'string')
    setIsDownloading(true)

    str_name_buildings.map(building => currentBuildings.filter(item => item.value === building)[0].zones).forEach(zones => zones.forEach(zone => temp.push(zone.id)))
    int_name_buildings.push.apply(int_name_buildings, temp)
    unique_ids = [...new Set(int_name_buildings)]

    const requisition = {
      'zone_id': unique_ids,
      'start_date': `${moment(dateStart).format('YYYY-MM-DD')}`,
      'end_date': `${moment(dateEnd).format('YYYY-MM-DD')}`,
      'language': lang,
      ...(pdfFormType !== '' && { 'pdf_form_type': pdfFormType })
    };

    callAPI(DOWNLOAD_FILE(type), requisition)
      .then(() => {
        setIsDownloading(false)
      })
  }

  function change_option(option) {
    setCurrentOption(option)
  }

  async function go_next() {
    if (currentStep === 1 && currentBuildingsChecked.length === 0) {
      setDisplayMessage(false)
      await delay(ms_timeout);
      setDisplayMessage(true)
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  function renderDownloadOrLoadingIcon(download_type) {
    if (isDownloading) {
      return (
      <div>
        <div className='loader loader-extended'/>
      </div>
      )
    } else {
      return (
        <div className={classList({
            "dropdown-content-next": true})}
            onClick={() => download(download_type)}>
          <i className="ico ico--download"/>
        </div>
      )
    }
  }

  function go_back() {
    setCurrentStep(currentStep - 1)
  }

  function getDownloadType() {
    const downloadTypeMap = {
      [QR_OPTION]: "qr",
      [CSV_OPTION]: "csv",
      [PDF_OPTION]: "pdf"
    };

    return downloadTypeMap[currentOption];
  }

  useEffect(() => {
    function handleOutsideClick(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownStatus(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (data && data.sent_by_email ) {
      if (currentOption === PDF_OPTION && currentStep === 3) {
        setCurrentStep(4)
      }
      if (currentStep === 2) {
        setCurrentStep(3)
      }
    }
  }, [data]);

  return (
    <div ref={dropdownRef} className={classList({
      'dropdown': is_filter,
      'dropdown-filter': !is_filter
    })}>
      <button onClick={() => setDropdownStatus(!dropdownStatus)} className={classList({
        'dropbtn': is_filter,
        'dropbtn-filter': !is_filter
      })}>
        {is_filter ? (
          <div className="dropdown-building-button">
            {t(`notifications.zone_building_button`)}
            <i className="ico ico--folder-solid" />
          </div>
        ) : (
          <div className="dropdown-building-button">
            {/* {t(`qr.qr_select_button`)} */}
            <i className="ico ico--download" />
          </div>
        )}
      </button>
      {(!is_filter) ? (
        <div className={classList({
          'dropdown-content': true,
          'show': dropdownStatus
        })}>
          {
            (currentStep === 1) ? (
              <div>
                <div className='dropdown-content-one'>
                  <div className='dropdown-content-header-one'>
                    <div className='dropdown-content-chooseoptions'>
                      <div className={classList({
                              "dropdown-content-download": true,
                              "picker-selected": (currentOption === QR_OPTION)
                            })} onClick={() => change_option(QR_OPTION)}>
                        <i className="ico ico--qrcode-solid"/>
                      </div>
                      <div className={classList({
                              "dropdown-content-download": true,
                              "picker-selected": (currentOption === CSV_OPTION)
                            })} onClick={() => change_option(CSV_OPTION)}>
                        <i className="ico ico--file-csv-solid"/>
                      </div>
                      <div className={classList({
                              "dropdown-content-download": true,
                              "picker-selected": (currentOption === PDF_OPTION)
                            })} onClick={() => change_option(PDF_OPTION)}>
                        <i className="ico ico--file-pdf-solid"/>
                      </div>
                    </div>
                  </div>
                  <div className='dropdown-content-middle'>
                    <div className="dropdown-content-overflow">
                    {
                      (currentBuildings !== undefined && currentBuildings.length > 0) ? (
                        currentBuildings.map((build, i) => (
                          <div>
                            <div className="dropdown-row">
                              <div className={classList({
                                "ico ico--plus-solid dropdown-expand": true,
                                "d-none": currentOption === PDF_OPTION
                              })} onClick={() => buildingOpenTree(i)}/>
                              <div className="dropdown-building-ckeckbox">
                                <input className="dropdown-ckeckbox"
                                checked={(hasAllValues(build.zones.map(zone => zone.id), currentBuildingsChecked))}
                                onChange={e => buildingChecked(build.zones.map(zone => zone.id), e.target.checked)}
                                ref={input => {
                                  if (input) {
                                    input.indeterminate = (haveCommonValue(build.zones.map(zone => zone.id), currentBuildingsChecked) && !hasAllValues(build.zones.map(zone => zone.id), currentBuildingsChecked))
                                  }
                                }}
                                type="checkbox" />
                                <label className="dropdown-label">{build.value}</label>
                              </div>
                            </div>
                            {
                            (build.zones !== undefined) ? (
                              build.zones.map(zone => (
                                <div className={classList({
                                  "dropdown-building-ckeckbox": true,
                                  "dropdown-building-ckeckbox-zones": true,
                                  "checkbox-zone-hide": (!build.tree || currentOption === PDF_OPTION)
                                })}>
                                  {
                                    (is_filter) ? (
                                      <>
                                        <input className="dropdown-ckeckbox"
                                          checked={currentBuildingsChecked.includes(zone.value) || currentBuildingsChecked.includes(build.value)}
                                          onChange={e => buildingChecked(zone.value, e.target.checked)}
                                          disabled={currentOption === PDF_OPTION}
                                          type="checkbox" />
                                        <label className="dropdown-label">{zone.value}</label>
                                      </>
                                    ) : (
                                      <>
                                        <input className="dropdown-ckeckbox"
                                          checked={currentBuildingsChecked.includes(zone.id) || currentBuildingsChecked.includes(build.value)}
                                          onChange={e => buildingChecked(zone.id, e.target.checked)}
                                          disabled={currentOption === PDF_OPTION}
                                          type="checkbox" />
                                        <label className="dropdown-label">{zone.value}</label>
                                      </>
                                    )
                                  }
                                </div>
                              ))
                            ) : false
                            }
                          </div>
                        ))
                      ) : (
                        <div className="dropdown-dissable">{t(`notifications.no_options`)}</div>
                      )
                    }
                    </div>
                  </div>
                  <div className={classList({
                                    "d-none": displayMessage,
                                    "p-1": true,
                                    "dropdown-content-error-message": true
                                  })}>
                      {t(`notifications.error_message`)}
                  </div>
                  <div className='dropdown-content-footer'>
                    <div className={classList({
                              "dropdown-content-back": true,
                              "invisible": (currentStep === 1)})}
                          onClick={() => go_back()}>
                      <i className="ico ico--arrow-left-solid" />
                    </div>
                    <div className={classList({
                              "dropdown-content-next": true})}
                          onClick={() => go_next()}>
                      <i className="ico ico--arrow-right-solid" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (currentStep === 2 && currentOption === PDF_OPTION) ? (
              <div>
                <div className='dropdown-content-two'>
                  <div className='dropdown-content-header'>
                    {t(`download.select_pdf_form_title`)}
                  </div>
                  <div className='dropdown-content-middle'>
                    <div>
                      <div className='dropdown-content-pdf-form'>
                        <div className='dropdown-content-two-pdf-form'>
                          <input
                            type="radio"
                            name="pdfFormType"
                            value="general"
                            checked={pdfFormType === 'general'}
                            onChange={(e) => {
                              setPdfFormType(e.target.value);
                            }}
                            className='mr-2'
                          />
                          <label>{t(`download.pdf_form_general`)}</label>
                        </div>
                        <div className='dropdown-content-two-pdf-form'>
                          <input
                            type="radio"
                            name="pdfFormType"
                            value="meeting_rooms"
                            checked={pdfFormType === 'meeting_rooms'}
                            onChange={(e) => {
                              setPdfFormType(e.target.value);
                            }}
                            className='mr-2'
                          />
                          <label>{t(`download.pdf_form_meeting_rooms`)}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='dropdown-content-footer'>
                    <div className={classList({
                              "dropdown-content-back": true})}
                          onClick={() => go_back()}>
                      <i className="ico ico--arrow-left-solid" />
                    </div>
                    <div className={classList({
                              "dropdown-content-next": true,
                              "disabled": !pdfFormType})}
                          onClick={() => {
                            if (pdfFormType) {
                              go_next();
                            }
                          }}>
                      <i className="ico ico--arrow-right-solid" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (currentStep === 4 && currentOption === PDF_OPTION) ? (
              <div>
                <div className='dropdown-content-three'>
                  <div className='dropdown-content-header'>
                    {t(`download.message`)}
                  </div>
                  <div className='dropdown-content-middle'>
                    <div className='dropdown-content-emailresponse'>
                    {t(`download.sent_email`)}
                    </div>
                  </div>
                  <div className='dropdown-content-footer'>
                    <div className={classList({
                              "dropdown-content-next": true})}
                              onClick={() => {setDropdownStatus(!dropdownStatus)
                                              setCurrentStep(1)
                                              setCurrentBuildingsChecked([])}}>
                      <i className="ico ico--plus-solid" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (currentStep === 2 && currentOption === QR_OPTION) ? (
              <div>
                <div className='dropdown-content-two'>
                  <div className='dropdown-content-header'>
                    {t(`download.select_language_title`)}
                  </div>
                  <div className='dropdown-content-middle'>
                    <div>
                      <div className='dropdown-content-language'>
                        <div className='dropdown-content-two-language'>
                          <i className="ico ico--es" />
                          <input checked={lang === 'es'} type="radio" name="language" onChange={() => setLang('es')}></input>
                        </div>
                        <div className='dropdown-content-two-language'>
                          <i className="ico ico--ca" />
                          <input checked={lang === 'ca'} type="radio" name="language" onChange={() => setLang('ca')}></input>
                        </div>
                        <div className='dropdown-content-two-language'>
                          <i className="ico ico--gb" />
                          <input checked={lang === 'en'} type="radio" name="language" onChange={() => setLang('en')}></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='dropdown-content-footer'>
                    <div className={classList({
                              "dropdown-content-back": true,
                              "invisible": (currentStep === 1)})}
                          onClick={() => go_back()}>
                      <i className="ico ico--arrow-left-solid" />
                    </div>
                    {
                      renderDownloadOrLoadingIcon(getDownloadType())
                    }
                  </div>
                </div>
              </div>
            ) : (currentStep === 2 && (currentOption === CSV_OPTION) || currentStep === 3 && currentOption === PDF_OPTION) ? (
              <div>
                <div className='dropdown-content-two'>
                  <div className='dropdown-content-header'>
                    {t(`download.select_date_title`)}
                  </div>
                  <div className='dropdown-content-middle'>
                    <div>
                      <div className='dropdown-content-date'>
                        <div className='dropdown-content-two-date'>
                          <div className='date-picker range-picker'>
                            <ReactDatePicker
                              open={openRange}
                              onClickOutside={() => setOpenRange(!openRange)}
                              onInputClick={() => setOpenRange(!openRange)}
                              name='dateRange'
                              autoComplete='off'
                              locale={i18n.language}
                              className='form-control'
                              dateFormat='dd/MM/yyyy'
                              shouldCloseOnSelect={true}
                              onChange={onChangeRange}
                              startDate={dateStart}
                              endDate={dateEnd}
                              maxDate={new Date()}
                              selectsRange
                              isClearable
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='dropdown-content-footer'>
                    <div className={classList({
                              "dropdown-content-back": true,
                              "invisible": (currentStep === 1)})}
                          onClick={() => go_back()}>
                      <i className="ico ico--arrow-left-solid" />
                    </div>
                    {
                      renderDownloadOrLoadingIcon(getDownloadType())
                    }
                  </div>
                </div>
              </div>
            ) : (currentStep === 3 || currentStep === 4 && currentOption === PDF_OPTION) ? (
              <div>
                <div className='dropdown-content-three'>
                  <div className='dropdown-content-header'>
                    {t(`download.message`)}
                  </div>
                  <div className='dropdown-content-middle'>
                    <div className='dropdown-content-emailresponse'>
                    {t(`download.sent_email`)}
                    </div>
                  </div>
                  <div className='dropdown-content-footer'>
                    <div className={classList({
                              "dropdown-content-next": true})}
                              onClick={() => {setDropdownStatus(!dropdownStatus)
                                              setCurrentStep(1)
                                              setCurrentBuildingsChecked([])}}>
                      <i className="ico ico--plus-solid" />
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          }
        </div>
      ) : (
        <div className={classList({
          'dropdown-content': true,
          'dropdown-content-filter': is_filter,
          'show': dropdownStatus
        })}>
          <div>
              <div className='dropdown-content-one'>
                <div className='dropdown-content-middle'>
                  <div className="dropdown-content-overflow">
                    {currentBuildings !== undefined && currentBuildings.length > 0 ? (
                      currentBuildings.map((build, i) => (
                        <div>
                          <div className="dropdown-row">
                            <div className={classList({
                              "ico ico--plus-solid dropdown-expand": true,
                              "d-none": currentOption === PDF_OPTION
                            })} onClick={() => buildingOpenTree(i)}/>
                            <div className="dropdown-building-ckeckbox">
                              <input className="dropdown-ckeckbox"
                                checked={hasAllValues(build.zones.map(zone => zone.id), currentBuildingsChecked)}
                                onChange={e => buildingChecked(build.zones.map(zone => zone.id), e.target.checked)}
                                ref={input => {
                                  if (input) {
                                    input.indeterminate = haveCommonValue(build.zones.map(zone => zone.id), currentBuildingsChecked) && !hasAllValues(build.zones.map(zone => zone.id), currentBuildingsChecked);
                                  }
                                }}
                                type="checkbox" />
                              <label className="dropdown-label">{build.value}</label>
                            </div>
                          </div>
                          {build.zones !== undefined && (
                            build.zones.map(zone => (
                              <div className={classList({
                                "dropdown-building-ckeckbox": true,
                                "dropdown-building-ckeckbox-zones": true,
                                "checkbox-zone-hide": (!build.tree || currentOption === PDF_OPTION)
                              })}>
                                <input className="dropdown-ckeckbox"
                                  checked={currentBuildingsChecked.includes(zone.id) || currentBuildingsChecked.includes(build.value)}
                                  onChange={e => buildingChecked(zone.id, e.target.checked)}
                                  disabled={currentOption === PDF_OPTION}
                                  type="checkbox" />
                                <label className="dropdown-label">{zone.value}</label>
                              </div>
                            ))
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="dropdown-dissable">{t(`notifications.no_options`)}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
        </div>
      )}
    </div>
  );
}

export default withTranslation()(DropdownTree);
